import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

import pillarsEnergy from '../images/pillars-energy.png';
import pillarsRetail from '../images/pillars-retail.png';
import pillarsTech from '../images/pillars-technology.png';
import pillarsTransport from '../images/pillars-transportation.png';
import Blurb from './blurb';

const OurBusinessStyled = styled.div`
    background: linear-gradient(180deg, #e4e2db 0%, #ffffff 100%);
    padding-top: 100px;

    .the-pillars {
        margin-top: 45px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        img {
            align-self: center;
            width: 266px;
            margin: 7px;
            border-radius: 20px;
            border: 5px solid #fff;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
        }

        @media screen and (max-width: 768px) {
            img {
                width: 180px;
            }
        }
    }
`;

function OurBusiness({}) {
    return (
        <OurBusinessStyled>
            <Blurb
                title="Our Business"
                copy={[
                    'We are a growth company focused on innovative solutions across our retail, energy, and logistics operations.',
                    'Our vast network of more than 800 retail and fueling locations provide travelers with convenient stops that offer an incredible variety of amenities and products to make road travel easier. The Pilot Flying J travel center network includes locations in 44 states and 5 Canadian provinces with more than 790 restaurants and offers truck maintenance and tire service with Southern Tire Mart at Pilot Flying J. The  One9 Fuel Network connects a variety of fueling locations to provide smaller fleets and independent professional drivers with everyday value, convenience, credit and perks.',
                    "We supply more than 14 billion gallons of fuel per year with the third largest tanker fleet in North America. Our sourcing infrastructure, strong market presence and expertise in energy and logistics optimizes the distribution of fuel, DEF, bio and renewables. Our fleet also provides critical hauling and disposal services in our nation's busiest basins.",
                ]}
            />
            <div className="the-pillars">
                <LazyLoad height={200}>
                    <img src={pillarsRetail} width="274" alt="retail" />
                    <img src={pillarsTransport} width="274" alt="transport" />
                    <img src={pillarsTech} width="274" alt="tech" />
                    <img src={pillarsEnergy} width="274" alt="energy" />
                </LazyLoad>
            </div>
        </OurBusinessStyled>
    );
}

OurBusiness.propTypes = {};

export default OurBusiness;
