import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SectionHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1110px;
    margin: 40px 0;

    span {
        flex-grow: 1;
        height: 1px;
        background-color: #979797;
    }

    h2 {
        margin: 0 20px;
        font-size: 18px;
        font-weight: 300;
        font-style: italic;
        text-transform: uppercase;
    }
`;

const SectionHeader = ({ title }) => (
    <SectionHeaderStyled>
        <span></span>
        <h2>{title}</h2>
        <span></span>
    </SectionHeaderStyled>
);

SectionHeader.propTypes = {
    title: PropTypes.string.isRequired,
};

export default SectionHeader;
