import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

const ImageCardStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 348px;
    height: 180px;
    margin: 16px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
`;

const ImageCard = ({ source, companyName }) => (
    <ImageCardStyled>
        <LazyLoad height={300}>
            <img src={source} alt={`${companyName} Logo`} title={companyName} />
        </LazyLoad>
    </ImageCardStyled>
);

ImageCard.propTypes = {
    source: PropTypes.node.isRequired,
    companyName: PropTypes.string.isRequired,
};

export default ImageCard;
