import React from 'react';
import styled from 'styled-components';
import sashBig from '../images/sashBig.svg';
import sash from '../images/sash.svg';
import sashMobile from '../images/rectangle.svg';
import people1 from '../images/driver-home.png';
import people2 from '../images/field-home.png';
import people3 from '../images/ssc-home.png';
import Blurb from './blurb';
import LazyLoad from 'react-lazyload';
import Button from './button';

const OurPeopleStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 58px;

    div {
        margin-top: 28px;
    }

    @media screen and (max-width: 768px) {
        p {
            width: 82%;

            font: 400 14px roboto, sans-serif;
            line-height: 26px;
            color: #6c7279;
        }

        h1 {
            font-size: 28px;
            font-weight: bold;
        }
    }

    .team-members {
        padding-top: 40px;
        padding-bottom: 80px;
        display: flex;
        justify-content: center;
        background-image: url(${sash});
        background-repeat: no-repeat;
        background-size: cover;

        #image-wrapper {
            img {
                margin-top: 20px;
                width: 33.33%;
                height: auto;

                @media screen and (max-width: 768px) {
                    width: 90%;
                }
            }

            @media screen and (max-width: 768px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        @media screen and (min-width: 1500px) {
            background-image: url(${sashBig});
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 40px;
            background-image: url(${sashMobile});

            img {
                width: 80%;
            }
        }
    }
`;

function OurPeople({}) {
    return (
        <OurPeopleStyled>
            <Blurb
                title="Our People"
                copy={[
                    '“It is because of our people that we are the company we are today and will be in the future.” – James A. Haslam II',
                    'Our more than 30,000 team members fuel the innovation and growth of our company through their dedication to provide fuel, products and great service to our guests and customers across North America.',
                ]}
            />
            <Button
                buttonText="Explore Careers"
                buttonType="primary"
                link="http://jobs.pilotflyingj.com"
                isExternal
            />
            <div className="team-members">
                <LazyLoad height={200}>
                    <div id="image-wrapper">
                        <img
                            src={people2}
                            width="960"
                            height="1208"
                            alt="store team member"
                        />
                        <img
                            src={people1}
                            width="960"
                            height="1208"
                            alt="driver"
                        />
                        <img
                            src={people3}
                            width="960"
                            height="1208"
                            alt="support center team member"
                        />
                    </div>
                </LazyLoad>
            </div>
        </OurPeopleStyled>
    );
}

export default OurPeople;
