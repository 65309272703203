import styled from 'styled-components';

const IndexStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .what-we-do {
        display: flex;
        flex-direction: column;
        max-width: 1140px;
        text-align: center;
        margin-bottom: 24px;
        margin-top: 67px;

        h1 {
            font-size: 38px;
            font-weight: 700;
            font-style: italic;
            @media screen and (max-width: 768px) {
                font-size: 28px;
            }
        }

        .cards {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;

            aside {
                width: 348px;
                height: 302px;
                box-sizing: border-box;
            }
        }

        .internal-link {
            align-self: center;
            margin: 16px 0 62px 0;
        }
    }

    .video-section {
        width: 90%;
        max-width: 850px;
    }

    .video {
        position: relative;
        overflow: hidden;
        top: -43px;
        z-index: 3;
        border: solid 2px white;
        border-radius: 10px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding-bottom: 56.25%;
        height: 0;
        margin-bottom: -86px;

        @media screen and (max-width: 1500px) {
            top: -43px;
        }

        @media screen and (max-width: 1024px) {
            top: -50px;
        }

        @media screen and (max-width: 768px) {
            width: 90%;
        }
    }

    .video iframe,
    .video object,
    .video embed {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .our-people {
        position: relative;
        width: 100%;
    }

    .our-business {
        width: 100%;

        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .our-brands {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 58px;
        margin-bottom: 72px;

        .brand-logos {
            .row {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
            }
        }
    }
`;

export default IndexStyled;
