import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BlurbStyled = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }

    .info__intro__title {
        font-size: 38px;
        font-weight: 700;
        font-style: italic;
        text-transform: uppercase;
    }

    .info__intro__copy {
        width: 100%;
        max-width: 802px;
        margin: 25px 0 0 0;
        font: 400 16px roboto, sans-serif;
        text-align: center;
        line-height: 32px;
        color: #6b727a;

        @media screen and (max-width: 1024px) {
            font-size: 14px;
        }
    }
`;

const Blurb = ({ title, copy }) => {
    return (
        <BlurbStyled className="info__intro">
            <span className="info__intro__title">{title}</span>
            {copy.map((paragraph, index) => (
                <p className="info__intro__copy" key={index}>
                    {paragraph}
                </p>
            ))}
        </BlurbStyled>
    );
};

Blurb.propTypes = {
    title: PropTypes.string.isRequired,
    copy: PropTypes.array.isRequired,
};

export default Blurb;
