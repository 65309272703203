import React from 'react';
import { withSize } from 'react-sizeme';

import Blurb from '../components/blurb';
import Hero from '../components/hero';
import ImageCard from '../components/imageCard';
import IndexStyled from '../components/page-styles/index-styles';
import Layout from '../components/layout';
import OurPeople from '../components/ourPeople';
import OurBusiness from '../components/ourBusiness';
import SectionHeader from '../components/sectionHeader';
import SEO from '../components/seo';

import BunkerHill from '../images/companies/bunker-hill.png';
import EZTrip from '../images/companies/e-z-trip.png';
import FlyingJ from '../images/companies/flying-j.png';
import heroImageDesktop from '../images/hero-bg.jpg';
import heroImageMobile from '../images/hero-mobile.png';
import MrFuel from '../images/companies/mr-fuel.png';
import One9 from '../images/companies/one-9.png';
import PartsAndServiceSolutions from '../images/companies/parts-and-service-solutions.png';
import Pilot from '../images/companies/pilot.png';
import PilotFlyingJ from '../images/companies/pilot-flying-j.png';
import PilotFlyingJTruckCare from '../images/companies/pilot-flying-j-truck-care.png';

import SouthernTireMart from '../images/companies/STMP.png';

import PilotWaterSolutions from '../images/companies/pilot-water-solutions.png';
import Pride from '../images/companies/pride.png';
import ProPetroleum from '../images/companies/pro-petroleum.png';
import Saratoga from '../images/companies/Saratoga.png';

import ScFuels from '../images/companies/SC-Fuels.png';
import Stamart from '../images/companies/stamart.png';
import TartanOil from '../images/companies/tartan-oil.png';
import XPressFuel from '../images/companies/xpress-fuel.png';

const IndexPage = ({ path, size }) => (
    <Layout path={path}>
        <IndexStyled>
            <SEO
                title="Pilot Company | Pilot Flying J Fuel Network & Travel Services"
                description="Pilot Company is a growth company focused on travel services as one of the leading suppliers of fuel & the largest operator of travel centers in North America."
            />
            <Hero
                text="Pilot Company keeps interstate travelers moving as one of the leading suppliers of fuel and the largest operator of travel centers in North America."
                background={
                    size.width > 768 ? heroImageDesktop : heroImageMobile
                }
            ></Hero>
            <section className="our-business">
                <OurBusiness />
            </section>
            <section className="our-people">
                <OurPeople />
            </section>
            <section className="our-brands info">
                <Blurb
                    title="Our Brands"
                    copy={[
                        'Our family of brands work together across all areas of our business to supply the fuel, resources and services our customers and guests need to keep commerce moving.',
                    ]}
                />
                <SectionHeader title="Fuel Networks" />
                <section className="brand-logos">
                    <div className="row">
                        {' '}
                        <a
                            href="https://www.pilotflyingj.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="Fuel Networks"
                        >
                            <ImageCard
                                source={PilotFlyingJ}
                                companyName="Pilot Flying J"
                            />
                        </a>
                        <a
                            href="https://www.one9fuelnetwork.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="one9 fuel network"
                        >
                            <ImageCard source={One9} companyName="One9" />
                        </a>
                    </div>
                </section>
                <SectionHeader title="Travel Centers" />
                <section className="brand-logos">
                    <div className="row">
                        <a
                            href="https://www.pilotflyingj.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="Pilot travel Centers"
                        >
                            <ImageCard source={Pilot} companyName="Pilot" />
                        </a>

                        <a
                            href="https://www.pilotflyingj.com"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="Flying J travel centers"
                        >
                            <ImageCard
                                source={FlyingJ}
                                companyName="Flying J"
                            />
                        </a>
                    </div>
                    <div className="row">
                        <ImageCard source={MrFuel} companyName="Mr. Fuel" />
                        <ImageCard source={Stamart} companyName="Stamart" />
                        <ImageCard source={Pride} companyName="Pride" />
                    </div>
                    <div className="row">
                        <ImageCard source={EZTrip} companyName="E-Z Trip" />

                        <ImageCard
                            source={XPressFuel}
                            companyName="XPress Fuel"
                        />
                    </div>
                </section>
                <SectionHeader title="Travel Services" />
                <section className="brand-logos">
                    <div className="row">
                        <a
                            href="https://stmpilot.com/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="Southern Tire Mart"
                        >
                        <ImageCard
                                source={SouthernTireMart}
                                companyName="Southern Tire Mart"
                            />
                        </a>

                        <a
                            href="https://www.partsandservicesolutions.com/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="parts services solutions"
                        >
                            <ImageCard
                                source={PartsAndServiceSolutions}
                                companyName="Parts and Service Solutions"
                            />
                        </a>
                    </div>
                </section>
                <SectionHeader title="Other Affiliated Companies" />
                <section className="brand-logos">
                    <div className="row">
                        <a
                            href="https://www.saratogarack.com/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="saratoga rack marketing"
                        >
                            <ImageCard
                                source={Saratoga}
                                companyName="Saratoga"
                            />
                        </a>
                        <a
                            href="https://www.propetroleum.com/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="pro petroleum company"
                        >
                            <ImageCard
                                source={ProPetroleum}
                                companyName="Pro Petroleum"
                            />
                        </a>
                    </div>
                    <div className="row">
                        <a
                            href="https://www.scfuels.com/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="SC Fuels"
                        >
                            <ImageCard
                                source={ScFuels}
                                companyName="SC Fuels"
                            />
                        </a>
                        <a
                            href="https://www.pilotwatersolutions.com/"
                            target="_blank"
                            rel="noreferrer noopener"
                            aria-label="pilot water solutions"
                        ><ImageCard
                            source={PilotWaterSolutions}
                            companyName="Pilot Water Solutions"
                        /></a>
                    </div>
                </section>
            </section>
        </IndexStyled>
    </Layout>
);

export default withSize()(IndexPage);
